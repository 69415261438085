// extracted by mini-css-extract-plugin
export var columnImage = "styles-module--columnImage--eca07";
export var columnText = "styles-module--columnText--ffba6";
export var content = "styles-module--content--51a29";
export var heading = "styles-module--heading--e4d5d";
export var image = "styles-module--image--a747d";
export var image_mobile = "styles-module--image_mobile--73aba";
export var link = "styles-module--link--e5edb";
export var overline = "styles-module--overline--62925";
export var twoColumns = "styles-module--twoColumns--76fd7";
export var twoColumns_inverted = "styles-module--twoColumns_inverted--59e18";