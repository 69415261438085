import React from "react";

export function Image(props) {
  const { image, className } = props;

  // if is react element
  if (React.isValidElement(image)) {
    return React.cloneElement(image, { className });
  }

  // if type is object
  if (typeof image === "object" && image.src) {
    return <img className={className} src={image.src} alt={image.alt} />;
  }

  // if type is function
  if (typeof image === "function") {
    return image(className);
  }
}
