import React from "react";
import { Link } from "gatsby-plugin-intl";
import { concatClassNames as cn } from "@system42/core";
import { ButtonLink, Headline3Sans, Overline } from "../../system42";
import * as styles from "./styles.module.css";
import { Image } from "../Image";

export function TwoColumns(props) {
  const {
    inverted,
    heading,
    content,
    linkTo,
    linkHref,
    linkText,
    image: imageData,
    imgSrc,
    imgAlt,
    overline,
    className,
  } = props;

  let image = null;
  if (imageData) {
    image = imageData;
  } else if (imgSrc) {
    image = { src: imgSrc, alt: imgAlt };
  }

  return (
    <div
      className={cn(
        className,
        styles.twoColumns,
        inverted && styles.twoColumns_inverted
      )}
    >
      <div className={styles.columnImage}>
        {image && <Image image={image} className={styles.image} />}
      </div>
      <div className={styles.columnText}>
        {image && (
          <Image
            image={image}
            className={cn(styles.image, styles.image_mobile)}
          />
        )}
        {overline && (
          <Overline className={styles.overline}>{overline}</Overline>
        )}
        <Headline3Sans className={styles.heading}>{heading}</Headline3Sans>
        <div className={styles.content}>{content}</div>
        {linkText && (
          <ButtonLink
            className={styles.link}
            customTag={linkTo ? Link : "a"}
            to={linkTo}
            href={linkHref}
          >
            {linkText}
          </ButtonLink>
        )}
      </div>
    </div>
  );
}
