import React from "react";
import { concatClassNames as cn } from "@system42/core";
import {
  ButtonPrimaryLarge,
  Headline1Sans,
  Headline2Sans,
  Overline,
  urlCreateAccount,
} from "../../system42";
import { FeatureIcon } from "../FeatureIcon";
import * as styles from "./styles.module.css";

export function HeaderOverlap(props) {
  const {
    featureIcon,
    featureIconAlt,
    overline,
    title,
    titleWidth,
    titleSize = "1",
    titleCustomTag,
    subtitle,
    subtitleWidth,
    buttonLabel,
    buttonSubtext,
    className,
    staticImage,
    imageSrc,
    imageAlt,
    isDisplayCta,
    ...containerProps
  } = props;

  return (
    <header className={cn(className, styles.headerOverlap)} {...containerProps}>
      <div className={styles.top}>
        {featureIcon && (
          <FeatureIcon className={styles.featureIcon}>
            <img src={featureIcon} alt={featureIconAlt} />
          </FeatureIcon>
        )}
        <Overline className={styles.overline}>{overline}</Overline>
        {titleSize === "1" && (
          <Headline1Sans
            customTag={titleCustomTag || "h1"}
            className={styles.title}
            style={{ maxWidth: titleWidth }}
          >
            {title}
          </Headline1Sans>
        )}
        {titleSize === "2" && (
          <Headline2Sans
            customTag={titleCustomTag || "h1"}
            className={styles.title}
            style={{ maxWidth: titleWidth }}
          >
            {title}
          </Headline2Sans>
        )}
        {subtitle && (
          <div className={styles.subtitle} style={{ maxWidth: subtitleWidth }}>
            {subtitle}
          </div>
        )}
        {isDisplayCta && (
          <ButtonPrimaryLarge
            className={styles.button}
            customTag={"a"}
            href={urlCreateAccount}
          >
            {buttonLabel ?? "Start free trial"}
          </ButtonPrimaryLarge>
        )}
        {isDisplayCta && (
          <div className={styles.buttonSubtext}>
            {buttonSubtext || "No credit card required"}
          </div>
        )}
      </div>
      <div className={styles.overlap}>
        {staticImage ? (
          staticImage(styles.image)
        ) : (
          <img src={imageSrc} className={styles.image} alt={imageAlt} />
        )}
      </div>
    </header>
  );
}
