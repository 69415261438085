// extracted by mini-css-extract-plugin
export var blockquote = "styles-module--blockquote--f7e86";
export var bubble = "styles-module--bubble--5def4";
export var figcaption = "styles-module--figcaption--93b48";
export var image = "styles-module--image--c6faa";
export var image_placeholder = "styles-module--image_placeholder--2be7b";
export var name = "styles-module--name--e0a1d";
export var position = "styles-module--position--f1076";
export var quoteTitle = "styles-module--quoteTitle--00c0f";
export var testimonials = "styles-module--testimonials--36a03";
export var testimonialsList = "styles-module--testimonialsList--7edba";
export var testimonialsListItem = "styles-module--testimonialsListItem--ee1a3";
export var title = "styles-module--title--5d903";
export var via = "styles-module--via--db6e6";