import React from "react";
import { concatClassNames as cn } from "@system42/core";
import * as styles from "./styles.module.css";
import { Headline2Sans } from "@simplease/system42-theme-userbrain";

export function Headline(props) {
  const {
    className,
    children,
    title,
    titleWidth,
    subtitle,
    subtitleWidth,
    ...restProps
  } = props;

  return (
    <div className={cn(className, styles.headline)} {...restProps}>
      <Headline2Sans style={{ maxWidth: titleWidth }} className={styles.title}>
        {title}
      </Headline2Sans>
      {subtitle && (
        <div style={{ maxWidth: subtitleWidth }} className={styles.subtitle}>
          {subtitle}
        </div>
      )}
    </div>
  );
}
